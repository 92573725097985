import React from "react";
import Layout from "components/Layout";
import CareersView from "../ducks/Careers";

export default function Careers() {
  return (
    <Layout title="Careers">
      <CareersView />
    </Layout>
  );
}

Careers.propTypes = {};
