import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  HeroTitle,
  Filters,
  FiltersContainer,
  LabelContainer,
  ListingsContainer,
  Listings,
  Wrapper,
  InfoLink,
  PostTitle,
  PostLink,
  PageLink,
  Posting,
  Pagination,
} from "./styles";
import axios from "axios";

const ITEMS_PER_PAGE = 10;

const loadCareers = async (
  setLocations,
  setPostings,
  setPaginationPage,
  setTotalPages,
  setFilterFlag
) => {
  const result = await axios.get(
    "https://api.smartrecruiters.com/v1/companies/Savant1/postings"
  );
  const content = result?.data?.content || [];
  // short circuit if there's nothing here.
  if (content.length === 0) {
    return null;
  }

  let locations = [];
  const richContent = content.map((posting) => {
    const urlName = posting.name
      .replace(/[\W_]+/g, "")
      .replace(/\s\s+/g, " ")
      .replace(/ /g, "-");
    const postingUrl = `https://jobs.smartrecruiters.com/Savant1/${posting.id}-${urlName}`;

    // set up the available cities
    const cityString = `${posting.location.city}, ${posting.location.region}`;
    if (!locations.includes(cityString)) {
      locations.push(cityString);
    }
    return {
      ...posting,
      postingUrl,
      locationId: cityString.replace(", ", "-"),
    };
  });
  setLocations(locations);
  setPostings(richContent);
  setTotalPages(Math.ceil(richContent.length / ITEMS_PER_PAGE));
  setPaginationPage(0);
  setFilterFlag(-1);
};

const Careers = ({ data = [] }) => {
  const [paginationPage, setPaginationPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [locations, setLocations] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterFlag, setFilterFlag] = useState(0);
  const [postings, setPostings] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    loadCareers(
      setLocations,
      setPostings,
      setPaginationPage,
      setTotalPages,
      setFilterFlag
    );
  }, []);

  const updateFilters = useCallback((evt) => {
    filters[evt.target.value] = !filters[evt.target.value];
    setFilters(filters);
    setFilterFlag(filterFlag + 1);
  });

  useEffect(() => {
    const newFilteredPosts = postings.filter((posting) => {
      let result =
        !filters[posting.locationId] &&
        !filters[
          posting.customField.filter((f) => f.fieldLabel === "Brands")[0]
            .valueLabel
        ];
      if (posting.location.remote) {
        result = result && !filters?.remote;
      } else {
        result = result && !filters?.local;
      }
      return result;
    });
    setFilteredPosts(newFilteredPosts);
    setTotalPages(Math.ceil(newFilteredPosts.length / ITEMS_PER_PAGE));
  }, [filterFlag]);

  return (
    <Container>
      <HeroTitle>Careers</HeroTitle>
      <div>
        <Wrapper>
          <FiltersContainer>
            <Filters>
              <h4>OPEN POSITIONS</h4>
              <br />
              <LabelContainer className="container">
                <span className="label-text">Savant</span>
                <input
                  type="checkbox"
                  id="savantCheckbox"
                  name="savantCheckbox"
                  value="Savant"
                  onChange={updateFilters}
                  checked={!filters["Savant"]}
                />
                <span className="checkmark"></span>
              </LabelContainer>
              <LabelContainer className="container">
                <span className="label-text">Savant Power</span>
                <input
                  type="checkbox"
                  id="racepointCheckbox"
                  name="racepointCheckbox"
                  value="Savant Power"
                  onChange={updateFilters}
                  checked={!filters["Savant Power"]}
                />
                <span className="checkmark"></span>
              </LabelContainer>

              <LabelContainer className="container">
                <span className="label-text">
                  GE Lighting, a Savant Company
                </span>
                <input
                  type="checkbox"
                  id="geCheckbox"
                  name="geCheckbox"
                  value="GE Lighting, a Savant Company"
                  onChange={updateFilters}
                  checked={!filters["GE Lighting, a Savant Company"]}
                />
                <span className="checkmark"></span>
              </LabelContainer>

              <br />
              <LabelContainer className="container">
                <span className="label-text">Local</span>
                <input
                  type="checkbox"
                  id="localCheckbox"
                  name="localCheckbox"
                  value="local"
                  onChange={updateFilters}
                  checked={!filters["local"]}
                />
                <span className="checkmark"></span>
              </LabelContainer>

              <LabelContainer className="container">
                <span className="label-text">Remote</span>
                <input
                  type="checkbox"
                  id="remoteCheckbox"
                  name="remoteCheckbox"
                  value="remote"
                  checked={!filters["remote"]}
                  onChange={updateFilters}
                />
                <span className="checkmark"></span>
              </LabelContainer>
              <br />
              {locations.map((location) => {
                const locationId = location.replace(", ", "-");
                return (
                  <LabelContainer
                    key={`location-${locationId}`}
                    className="container"
                  >
                    <span className="label-text">{location}</span>
                    <input
                      type="checkbox"
                      id={locationId}
                      name={locationId}
                      value={locationId}
                      onChange={updateFilters}
                      checked={!filters[locationId]}
                    />
                    <span className="checkmark"></span>
                  </LabelContainer>
                );
              })}

              <h6>
                <InfoLink href="/careers-privacy/en-us">
                  Privacy Policy USA
                </InfoLink>
              </h6>

              <h6>
                <InfoLink href="/careers-privacy/zh-cn">
                  Privacy Policy China
                </InfoLink>
              </h6>

              <h6>
                <InfoLink href="/careers-privacy/en-ca">
                  Privacy Policy Canada
                </InfoLink>
              </h6>

              <h6>
                <InfoLink href="/careers-privacy/fr-ca">
                  Privacy Policy French Canada
                </InfoLink>
              </h6>
            </Filters>
          </FiltersContainer>
          <ListingsContainer>
            <Listings>
              <ul>
                {filteredPosts
                  .slice(
                    paginationPage * ITEMS_PER_PAGE,
                    paginationPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                  )
                  .map((posting) => {
                    return (
                      <Posting key={`posting-${posting?.id}`}>
                        <div>
                          <PostTitle>{posting?.name}</PostTitle>
                        </div>
                        <div>
                          <div>
                            <PostLink
                              href={posting?.postingUrl}
                              target="_blank"
                            >
                              Read More
                            </PostLink>
                            <span>
                              {" "}
                              (
                              {
                                posting.customField.filter(
                                  (f) => f.fieldLabel === "Brands"
                                )[0].valueLabel
                              }
                              )
                            </span>
                          </div>
                        </div>
                      </Posting>
                    );
                  })}
              </ul>
            </Listings>
          </ListingsContainer>
        </Wrapper>
        <Pagination>
          {[...Array(totalPages).keys()].map((page) => {
            return (
              <PageLink
                key={`page-${page}`}
                onClick={() => setPaginationPage(page)}
                active={page === paginationPage}
              >
                {page + 1}
              </PageLink>
            );
          })}
          {paginationPage < totalPages - 1 && (
            <PageLink onClick={() => setPaginationPage(paginationPage + 1)}>
              NEXT
            </PageLink>
          )}
          <span style={{ width: "2em" }} />
        </Pagination>
      </div>
    </Container>
  );
};

export default Careers;
