import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "components/Theme/default-theme";
import LearnMoreBtn from "components/LearnMoreBtn";

export const HeroTitle = styled.h1`
  font-family: Gotham, serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 200;
  line-height: 50px;
  letter-spacing: 0;
  text-align: center;
  color: #382e2b;
  margin-top: 1em;
`;

export const Link = styled(LearnMoreBtn)`
  text-transform: initial;
  font-size: 18px;
  line-height: 27px;
`;

export const InternationalDealersList = styled.div``;

export const Title = styled.h4`
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #382e2b;
  width: 100%;
`;

export const Paragraph = styled(ReactMarkdown)`
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: #382e2b;
`;

export const Container = styled.div``;

export const Loader = styled.div`
  padding: 15px;
  text-align: center;
`;

export const CountrySectionTitle = styled.div`
  padding: 10px 0;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  background: #979797;
`;

export const NotFoundSection = styled.div`
  margin: 50px;
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #382e2b;
`;

export const ContainerInner = styled.div`
  padding: 0 15px;
  ${media.laptop} {
    max-width: 880px;
    margin: 0 auto;
  }
`;

export const FiltersContainer = styled.div`
  width: 33%;
  margin-top: 75px;
  padding: 0;
`;

export const Filters = styled.div`
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  text-align: left;
`;

export const LabelContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  font-family: "GothamBook", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5em;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #ebebeb;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 4px;
    width: 3px;
    height: 6px;
    border: solid #696057;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ListingsContainer = styled.div`
  width: 67%;
  margin-top: 75px;
  padding: 0;
`;
export const Listings = styled.ul``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoLink = styled.a`
  color: #ff5f00;
  text-decoration: none;
  font-family: "Gotham", "Helvetica", "Arial", sans-serif;
`;

export const PostTitle = styled.div`
  text-transform: uppercase;
  font-size: 1.25rem;
`;

export const PostLink = styled(InfoLink)`
  text-transform: uppercase;
`;

export const Posting = styled.div`
  list-style-type: none;
  margin: 0 0 25px 0;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PageLink = styled(InfoLink)`
  padding-left: 0.5em;
  margin-bottom: 1em;
  margin-top: -1em;
  text-decoration: ${(p) => (p.active ? "underline" : "none")};
  cursor: pointer;
`;
